import {
  GET_SUPPLIER_INVOICE_DATA,
  SET_SUPPLIER_DATA_LOADING,
  GET_ALL_SUPPLIERS,
  GET_SEARCH_PRODUCTS,
} from './types';
import store from '../../../../store';
import Axios from '../../../../common/util/Axios';

// TODO: add selected date range here in future
export const getSupplierInvoiceData = () => async (dispatch) => {
  try {
    dispatch({ type: SET_SUPPLIER_DATA_LOADING });
    const companyId = store.getState().authState.companyId;
    const branchId = store.getState().authState.branchId;
    const res = await Axios.get(`/api/dashboard/supplier-invoice/find-all`, {
      params: { companyId, branchId },
    });

    dispatch({
      type: GET_SUPPLIER_INVOICE_DATA,
      payload: res.data.data,
    });
  } catch (err) {}
};

export const getAllSuppliers = () => async (dispatch) => {
  try {
    const companyId = store.getState().authState.companyId;
    const res = await Axios.get(`/api/dashboard/suppliers/find-all`, {
      params: { companyId },
    });

    dispatch({
      type: GET_ALL_SUPPLIERS,
      payload: res.data.data,
    });
  } catch (err) {}
};

export const searchProducts = (keyword) => async (dispatch) => {
  try {
    const companyId = store.getState().authState.companyId;
    const res = await Axios.get(
      `/api/dashboard/products/search-by-code-name-barcode`,
      {
        params: { companyId, keyword },
      }
    );

    dispatch({
      type: GET_SEARCH_PRODUCTS,
      payload: res.data.data,
    });
  } catch (err) {}
};

export const createSupplierInvoice =
  (supplierInvoiceBody) => async (dispatch) => {
    try {
      const companyId = store.getState().authState.companyId;
      const branchId = store.getState().authState.branchId;
      const res = await Axios.post(`/api/dashboard/supplier-invoice`, {
        ...supplierInvoiceBody,
        companyId,
        branchId,
      });

      return true;
    } catch (err) {
      return true;
    }
  };
