import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';

// Redux
import { Provider } from 'react-redux';
import store from './store';

import MainContainer from './MainContainer';
import Layout from './modules/layout/components';
import Dashboard from './modules/dashboard/components';
import Alert from './common/components/Alert';
import Landing from './modules/auth/components';
import Products from './modules/inventory/products/components';
import Stocks from './modules/inventory/stocks/components';
import Promotions from './modules/inventory/promotions/components';
import Suppliers from './modules/inventory/suppliers/components';
import AddNewSupplier from './modules/inventory/suppliers/components/AddNewSupplier';
import Stats from './modules/inventory/stats/components';
import Invoices from './modules/inventory/invoices/components';
import AddNewInvoice from './modules/inventory/invoices/components/AddNewInvoice';
import AddNewProduct from './modules/inventory/products/components/AddNewProduct';
import Categories from './modules/inventory/categories/components';
import AddNewCategory from './modules/inventory/categories/components/AddNewCategory';

const App = () => {
  return (
    <Provider store={store}>
      <Alert />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainContainer />}>
            <Route path="/" element={<Layout />}>
              <Route index element={<Dashboard />} />
              <Route path="/inventory">
                <Route path="/inventory/products">
                  <Route path="/inventory/products" element={<Products />} />
                  <Route
                    path="/inventory/products/new-product"
                    element={<AddNewProduct />}
                  />
                </Route>
                <Route path="/inventory/stocks" element={<Stocks />} />
                <Route path="/inventory/suppliers" element={<Suppliers />} />
                <Route path="/inventory/invoices">
                  <Route path="/inventory/invoices" element={<Invoices />} />
                  <Route
                    path="/inventory/invoices/new-invoice"
                    element={<AddNewInvoice />}
                  />
                </Route>
                <Route path="/inventory/stats" element={<Stats />} />
                <Route path="/inventory/categories">
                  <Route
                    path="/inventory/categories"
                    element={<Categories />}
                  />
                  <Route
                    path="/inventory/categories/new-category"
                    element={<AddNewCategory />}
                  />
                </Route>
                <Route path="/inventory/suppliers">
                  <Route path="/inventory/suppliers" element={<Suppliers />} />
                  <Route
                    path="/inventory/suppliers/new-supplier"
                    element={<AddNewSupplier />}
                  />
                </Route>
              </Route>
              <Route path="/promotions" element={<Promotions />} />
              <Route path="/suppliers" element={<Suppliers />} />

              <Route path="/settings" element={<div>Settings</div>} />
            </Route>
            <Route path="/login" element={<Landing />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
