import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.css';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { InputLabel } from '@mui/material';
import { setBranchId } from '../../../../auth/actions';
import SearchableDropdown from '../../../../../common/components/SearchableDropdown';
import { getCategories } from '../../../categories/actions';
import { createProduct } from '../../actions';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../../../../../common/actions';

const AddNewProduct = memo(
  ({
    getCategories,
    categoriesState: { categoriesData },
    createProduct,
    showAlert,
  }) => {
    const [isLoading, setLoading] = useState(false);
    const [productForm, setProductForm] = useState({
      code: null,
      name: null,
      categoryid: null,
      barcode: null,
      dateadded: null,
      companyId: null,
      branchId: null,
    });
    const navigate = useNavigate();

    useEffect(() => {
      getCategories();
    }, []);

    const onChangeTextField = (event) => {
      setProductForm({
        ...productForm,
        [event.target.id]: event.target.value,
      });
    };

    const onSelectCategory = (selectItem) => {
      setProductForm({
        ...productForm,
        categoryid: selectItem.id,
      });
    };

    const onSubmit = async () => {
      if (!productForm.name) {
        showAlert({
          isAlertOpen: true,
          alertType: 'danger',
          alertMessage: 'Please Enter a Product Name!',
        });
      } else if (!productForm.categoryid) {
        showAlert({
          isAlertOpen: true,
          alertType: 'danger',
          alertMessage: 'Please Select a Category!',
        });
      } else {
        setLoading(true);
        const isSuccess = await createProduct({
          ...productForm,
          dateadded: new Date().toISOString().split('T')[0],
        });

        setLoading(false);

        if (isSuccess) {
          showAlert({
            isAlertOpen: true,
            alertType: 'success',
            alertMessage: 'Product Created Successfully !',
          });
          navigate('/inventory/products');
        } else {
          showAlert({
            isAlertOpen: true,
            alertType: 'danger',
            alertMessage: 'Operation Failed. Please try again later !',
          });
        }
      }
    };

    const onClickCancel = () => {
      navigate('/inventory/products');
    };

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>New Product</h1>
        </div>
        <div className={styles.body}>
          <div className={styles.formBody}>
            <div>
              <div className={styles.textInputContainer}>
                <h4 className={styles.textInputLabel}>Code</h4>
                <input
                  id="code"
                  className={styles.textInput}
                  type="text"
                  onChange={onChangeTextField}
                />
              </div>
              <div className={styles.textInputContainer}>
                <h4 className={styles.textInputLabel}>Name</h4>
                <input
                  id="name"
                  className={styles.textInput}
                  type="text"
                  onChange={onChangeTextField}
                />
              </div>
              <div className={styles.textInputContainer}>
                <h4 className={styles.textInputLabel}>Barcode</h4>
                <input
                  id="barcode"
                  className={styles.textInput}
                  type="text"
                  onChange={onChangeTextField}
                />
              </div>
              <div className={styles.textInputContainer}>
                <h4 className={styles.textInputLabel}>Category</h4>
                <div className={styles.dropdownContainer}>
                  <SearchableDropdown
                    options={categoriesData}
                    showKeys={['name']}
                    placeholder="Not Selected"
                    onSelect={onSelectCategory}
                  />
                </div>
              </div>
            </div>

            <div className={styles.buttonPanelContainer}>
              <div
                className={styles.submitButton}
                onClick={!isLoading && onSubmit}
                aria-disabled={isLoading}
              >
                {isLoading ? 'Loading...' : 'Submit'}
              </div>
              <div className={styles.cancelButton} onClick={onClickCancel}>
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const mapStateToProps = (state) => ({
  productsState: state.productsState,
  auth: state.authState,
  categoriesState: state.categoriesState,
});

export default connect(mapStateToProps, {
  setBranchId,
  getCategories,
  createProduct,
  showAlert,
})(AddNewProduct);
