import React, { useEffect, useState, memo } from "react";
import styles from "./index.module.css";
import { connect } from "react-redux";
import { setBranchId } from "../../../../auth/actions";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { InputLabel } from "@mui/material";
import PropTypes from "prop-types";
import TableComp from "./Table";
import SearchableDropdown from "../../../../../common/components/SearchableDropdown";
import AddItemPopup from "./AddItemPopup";
import { createSupplierInvoice, getAllSuppliers } from "../../actions";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../../../../../common/actions";

const AddNewInvoice = memo(({
  setBranchId,
  auth: {
    branchList,
    branchId,
  },
  getAllSuppliers,
  supplierInvoiceState: {
    allSuppliers
  },
  showAlert,
  createSupplierInvoice,
}) => {

  const [isOpenAddInvoicePopup, setOpenAddInvoicePopup] = useState(false);
  const [supplierInvoiceForm, setSupplierInvoiceForm] = useState({
    invoiceNo: null,
    supplierId: null,
    totalAmount: 0,
    totalDiscount: 0,
    totalPayable: 0,
    paidAmount: 0,
    balanceAmount: 0,
    paymentMethod: null,
    companyId: null,
    branchId: null,
    stockList: [],
  });
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChangeBranch = (event) => {
    setBranchId(event.target.value);
  };

  const onClickAddItem = () => {
    setOpenAddInvoicePopup(true);
  }

  const onChangeInvoiceNo = (event) => {
    setSupplierInvoiceForm({
      ...supplierInvoiceForm,
      invoiceNo: event.target.value,
    });
  }

  const onSelectSupplier = (selectItem) => {
    setSupplierInvoiceForm({
      ...supplierInvoiceForm,
      supplierId: selectItem.id,
    });
  }

  const onSelectPaymentMethod = (selectItem) => {
    setSupplierInvoiceForm({
      ...supplierInvoiceForm,
      paymentMethod: selectItem.name,
    });
  }

  const onChangePaidAmount = (event) => {

    const paidAmount = parseFloat(event.target.value);
    const balanceAmount = paidAmount - supplierInvoiceForm.totalPayable;

    setSupplierInvoiceForm({
      ...supplierInvoiceForm,
      paidAmount,
      balanceAmount,
    });
  }

  useEffect(() => {
    getAllSuppliers();
  }, []);

  const paymentMethods = [
    {
      id: 1,
      name: "Cash",
    },
    {
      id: 2,
      name: "Card",
    },
    {
      id: 3,
      name: "Cheque",
    },
    {
      id: 4,
      name: "Credit",
    }
  ];

  const addStockToInvoice = (invoiceStock) => {
    const newStockList = [...supplierInvoiceForm.stockList];
    newStockList.push(invoiceStock);

    let newTotalAmount = 0;
    let newTotalDiscount = 0;
    let newTotalPayable = 0;
    for (const stockItem of newStockList) {
      const stockTotalAmount = stockItem.unitPrice * stockItem.qty;
      newTotalAmount += stockTotalAmount;

      const stockPayableAmount = stockItem.totalAmount;
      newTotalPayable += stockPayableAmount;

      const stockDiscountAmount = stockTotalAmount - stockPayableAmount;
      newTotalDiscount += stockDiscountAmount;
    }

    let newBalanceAmount = 0;
    if (supplierInvoiceForm.paidAmount && supplierInvoiceForm.paidAmount > 0) {
      newBalanceAmount = supplierInvoiceForm.paidAmount - newTotalPayable;
    }

    setSupplierInvoiceForm({
      ...supplierInvoiceForm,
      totalAmount: newTotalAmount,
      totalDiscount: newTotalDiscount,
      totalPayable: newTotalPayable,
      balanceAmount: newBalanceAmount,
      stockList: newStockList,
    });
  }

  const onSubmit = async () => {

    if (!supplierInvoiceForm.supplierId) {
      showAlert({
        isAlertOpen: true,
        alertType: "danger",
        alertMessage: "Please Select a Supplier!",
      });
    } else if (!supplierInvoiceForm.paymentMethod) {
      showAlert({
        isAlertOpen: true,
        alertType: "danger",
        alertMessage: "Please Select a Payment Method!",
      });
    } else if (supplierInvoiceForm.stockList.length === 0) {
      showAlert({
        isAlertOpen: true,
        alertType: "danger",
        alertMessage: "Please add an Item to the Invoice!",
      });
    } else {

      setLoading(true);

      const invoiceItemList = [];
      for (const invoiceItem of supplierInvoiceForm.stockList) {
        const finalInvoiceItem = {
          ...invoiceItem,
        }
        delete finalInvoiceItem.product;
        invoiceItemList.push(finalInvoiceItem);
      }

      const supplierInvoiceObj = {
        ...supplierInvoiceForm,
        stockList: invoiceItemList,
      }

      const isSuccessCreatedInvoice = await createSupplierInvoice(supplierInvoiceObj);

      setLoading(false);

      if (isSuccessCreatedInvoice) {
        showAlert({
          isAlertOpen: true,
          alertType: "success",
          alertMessage: "Supplier Invoice Created Successfully !",
        });
        navigate("/inventory/invoices");
      } else {
        showAlert({
          isAlertOpen: true,
          alertType: "danger",
          alertMessage: "Operation Failed. Please try again later !",
        });
      }

    }
  }

  const onClickCancel = () => {
    navigate("/inventory/invoices");
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>New Invoice</h1>
        {branchId && (
          <FormControl className={"branch-select-dropdown"} size="small">
            <InputLabel id="test-select-label">Branch</InputLabel>
            <Select
              labelId="test-select-label"
              id="demo-simple-select-helper"
              value={branchId}
              label="Branch"
              onChange={handleChangeBranch}
            >
              {branchList.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <div className={styles.body}>
        <div className={styles.chartMainDiv}>
          <div className={styles.topDiv}>
            <div className={styles.textInputContainer}>
              <h4 className={styles.textInputLabel}>Invoice Number</h4>
              <input className={styles.textInput} type="text" onChange={onChangeInvoiceNo} />
            </div>
            <div className={styles.textInputContainer}>
              <h4 className={styles.textInputLabel}>Supplier</h4>
              <div className={styles.dropdownContainer}>
                <SearchableDropdown
                  options={allSuppliers}
                  showKeys={["companyname"]}
                  placeholder="Not Selected"
                  onSelect={onSelectSupplier}
                />
              </div>
            </div>
            <div className={styles.textInputContainer}>
              <h4 className={styles.textInputLabel}>Payment Method</h4>
              <div className={styles.dropdownContainer}>
                <SearchableDropdown
                  options={paymentMethods}
                  showKeys={["name"]}
                  placeholder="Not Selected"
                  onSelect={onSelectPaymentMethod}
                />
              </div>
            </div>
            <div className={styles.textInputContainer}>
              <h4 className={styles.textInputLabel}>Total Amount</h4>
              <input className={styles.textInput} type="text" disabled={true} value={supplierInvoiceForm.totalAmount} />
            </div>
            <div className={styles.textInputContainer}>
              <h4 className={styles.textInputLabel}>Discount</h4>
              <input className={styles.textInput} type="text" disabled={true} value={supplierInvoiceForm.totalDiscount} />
            </div>
            <div className={styles.textInputContainer}>
              <h4 className={styles.textInputLabel}>Payable</h4>
              <input className={styles.textInput} type="text" disabled={true} value={supplierInvoiceForm.totalPayable} />
            </div>
            <div className={styles.textInputContainer}>
              <h4 className={styles.textInputLabel}>Paid</h4>
              <input className={styles.textInput} type="text" onChange={onChangePaidAmount} />
            </div>
            <div className={styles.textInputContainer}>
              <h4 className={styles.textInputLabel}>Balance</h4>
              <input className={styles.textInput} type="text" disabled={true} value={supplierInvoiceForm.balanceAmount} />
            </div>
          </div>

          <div className={styles.addItemButtonContainer}>
            <div className={styles.addItemButton} onClick={onClickAddItem}>
              Add Item
            </div>
            {isOpenAddInvoicePopup &&
              <AddItemPopup
                addStockToInvoice={addStockToInvoice}
                isOpenAddInvoicePopup={isOpenAddInvoicePopup}
                setOpenAddInvoicePopup={setOpenAddInvoicePopup}
              />}
          </div>
          <div className={styles.chartDiv}>
            <TableComp
              invoiceStockData={supplierInvoiceForm.stockList}
            />
          </div>

          <div className={styles.buttonPanelContainer}>
            <div className={styles.submitButton} onClick={!isLoading && onSubmit} aria-disabled={isLoading}>
              {isLoading ? "Loading..." : "Submit"}
            </div>
            <div className={styles.cancelButton} onClick={onClickCancel}>
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

AddNewInvoice.propTypes = {
  setBranchId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  auth: state.authState,
  supplierInvoiceState: state.supplierInvoiceState,
});

export default connect(mapStateToProps, {
  setBranchId,
  getAllSuppliers,
  showAlert,
  createSupplierInvoice,
})(AddNewInvoice);
