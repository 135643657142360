import { SET_CATEGORIES, SET_CATEGORY_DATA_LOADING } from './types';
import store from '../../../../store';
import Axios from '../../../../common/util/Axios';

export const getCategories = (params) => async (dispatch) => {
  try {
    dispatch({ type: SET_CATEGORY_DATA_LOADING });
    const companyId = store.getState().authState.companyId;

    const queryParams = {
      ...params,
      companyId,
    };

    const res = await Axios.get(`/api/dashboard/categories/find-all`, {
      params: queryParams,
    });

    dispatch({
      type: SET_CATEGORIES,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: SET_CATEGORIES,
      payload: [],
    });
  }
};
