import { SET_SUPPLIERS, SET_SUPPLIERS_DATA_LOADING } from '../actions/types';

const initialState = {
  isLoading: false,
  suppliersData: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SUPPLIERS_DATA_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_SUPPLIERS:
      return {
        ...state,
        suppliersData: payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
