import React, { useEffect } from 'react';

const AddNewCategory = () => {
  useEffect(() => {
    console.log('OPENS THE PAGE');
  }, []);

  return <div>AddNewCategory</div>;
};

export default AddNewCategory;
