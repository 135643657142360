import React, { useState, memo } from "react";
import styles from "./index.module.css";

const SearchableDropdown = memo(({ 
    options, 
    showKeys = ["label"], 
    placeholder, 
    onSelect,
    onType, 
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedOption, setSelectedOption] = useState();

    let filteredOptions;
    if (!onType) {
        filteredOptions = options.filter(
            (option) =>
                option[showKeys[0]] &&
                option[showKeys[0]].toLowerCase().includes(searchTerm.toLowerCase()) &&
                option[showKeys[0]] !== selectedOption
        );
    } else {
        filteredOptions = options;
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        if (onType) {
            onType(e.target.value);
        }
    };

    const handleSelect = (option) => {
        setSelectedOption(option);
        onSelect(option);
        setIsOpen(false);
        setSearchTerm(option[showKeys[0]]);
    };

    return (
        <div className={styles.dropdown}>
            <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder={placeholder}
                className={styles.textInput}
                onClick={toggleDropdown}
                // onBlur={toggleDropdown}
            />
            <div className={`${styles.dropdownContent} ${isOpen ? styles.show : ""}`}>
                {filteredOptions.length > 0 ? (
                    filteredOptions.map((option, index) => (
                        <div
                            key={index}
                            className={styles.dropdownItem}
                            onClick={() => handleSelect(option)}
                        >
                            {showKeys.map(item => (
                                <div className={styles.dropdownItemCell}>
                                    {option[item]}
                                </div>
                            ))}
                        </div>
                    ))
                ) : (
                    <div className={styles.noOptions}>No options</div>
                )}
            </div>
        </div>
    );
});

export default SearchableDropdown;