import React, { memo, useEffect, useState } from 'react';
import styles from './index.module.css';
import Table from '../../../../../common/components/Table';
import { getCategories } from '../../actions/index';
import { connect } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const CategoryTable = memo(
  ({
    getCategories,
    auth: { branchList, branchId },
    categoriesState: { categoriesData, isLoading },
  }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterParams, setFilterParams] = useState({});

    useEffect(() => {
      if (branchId) {
        getCategories(filterParams);
      }
    }, [filterParams, branchId]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const columns = [
      {
        id: 'name',
        label: 'Name',
        minWidth: 100,
      },
      {
        id: 'datecreated',
        label: 'Date Created',
        minWidth: 100,
        format: (value) => value.toFixed(2),
      },
    ];

    return (
      <div className={styles.mainDiv}>
        {isLoading === false ? (
          <Table columns={columns} rows={categoriesData} />
        ) : (
          <div>
            <Stack spacing={1}>
              <Skeleton variant="rounded" height={20} />
              <Skeleton variant="rounded" height={20} />
              <Skeleton variant="rounded" height={20} />
              <Skeleton variant="rounded" height={20} />
              <Skeleton variant="rounded" height={20} />
            </Stack>
          </div>
        )}
      </div>
    );
  }
);

const mapStateToProps = (state) => ({
  auth: state.authState,
  categoriesState: state.categoriesState,
});

export default connect(mapStateToProps, {
  getCategories,
})(CategoryTable);
