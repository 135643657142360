import {
    SET_PRODUCTS_DATA_LOADING,
    SET_PRODUCTS,
} from "../actions/types";

const initialState = {
    isLoading: false,
    productsData: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_PRODUCTS_DATA_LOADING:
            return {
                ...state,
                isLoading: payload,
            };
        case SET_PRODUCTS:
            return {
                ...state,
                productsData: payload,
                isLoading: false,
            };
        default:
            return state;
    }
}
