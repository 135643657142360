import React, { useState } from 'react';
import styles from './index.module.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setBranchId } from '../../../auth/actions';
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import { BiSolidLayer } from 'react-icons/bi';
import FilterDropDown from './FilterDopdown';
import FormControl from '@mui/material/FormControl';
import { InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SupplierTable from './SupplierTable';

const Suppliers = ({ setBranchId, auth: { branchList, branchId } }) => {
  const [selectedFilterItem, setSelectedFilterItem] = useState(null);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const navigate = useNavigate();

  const onClickNewCategory = () => {
    navigate('/inventory/categories/new-category');
  };

  const handleChangeBranch = (event) => {
    setBranchId(event.target.value);
  };

  const filterDropdownItems = [
    { id: 1, name: 'category one' },
    { id: 2, name: 'category two' },
    { id: 3, name: 'category three' },
    { id: 4, name: 'category four' },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Suppliers</h1>
        {/* {branchId && (
          <FormControl className={'branch-select-dropdown'} size="small">
            <InputLabel id="test-select-label">Branch</InputLabel>
            <Select
              labelId="test-select-label"
              id="demo-simple-select-helper"
              value={branchId}
              label="Branch"
              onChange={handleChangeBranch}
            >
              {branchList.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )} */}
      </div>
      <div className={styles.body}>
        <div className={styles.chartMainDiv}>
          <div className={styles.topDiv}>
            <div className={styles.leftDiv}>
              {/* <div
                className={styles.newStockButton}
                onClick={() => {
                  onClickNewCategory();
                }}
              >
                <BiSolidLayer className={styles.newStockButtonIcon} />
                New Category
              </div> */}
            </div>
            <div className={styles.rightDiv}>
              {/* <SearchBar />
              <>
                <FilterDropDown
                  selectedFilterItem={selectedFilterItem}
                  setSelectedFilterItem={setSelectedFilterItem}
                  showFilterDropdown={showFilterDropdown}
                  setShowFilterDropdown={setShowFilterDropdown}
                  filterDropdownItems={filterDropdownItems}
                />
              </> */}
            </div>
          </div>
          <div className={styles.chartDiv}>
            <SupplierTable />
          </div>
        </div>
      </div>
    </div>
  );
};

Suppliers.propTypes = {
  setBranchId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  auth: state.authState,
});

export default connect(mapStateToProps, {
  setBranchId,
})(Suppliers);
