import { SET_STOCKS, SET_STOCKS_DATA_LOADING } from './types';
import store from '../../../../store';
import Axios from '../../../../common/util/Axios';

export const getStocks = (params) => async (dispatch) => {
  try {
    dispatch({ type: SET_STOCKS_DATA_LOADING });
    const companyId = store.getState().authState.companyId;
    const branchId = store.getState().authState.branchId;
    const queryParams = {
      ...params,
      'stock.companyId': companyId,
      branchId,
      'qty[gt]': 0,
    };

    const res = await Axios.get(
      `/api/dashboard/stocks/find-all-with-promotions`,
      {
        params: queryParams,
      }
    );

    dispatch({
      type: SET_STOCKS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: SET_STOCKS,
      payload: [],
    });
  }
};
