import React, { memo, useEffect, useState } from 'react';
import styles from './index.module.css';
import Table from '../../../../../common/components/Table';
import { getStocks } from '../../actions/index';
import { connect } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const StockTable = memo(
  ({
    getStocks,
    auth: { branchList, branchId },
    stocksState: { stocksData, isLoading },
  }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterParams, setFilterParams] = useState({});

    useEffect(() => {
      if (branchId) {
        getStocks(filterParams);
      }
    }, [filterParams, branchId]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    useEffect(() => {
      console.log('stocks data', stocksData);
    }, [stocksData]);

    const columns = [
      {
        id: 'productName',
        label: 'Product',
        minWidth: 170,
      },
      {
        id: 'initial_qty',
        label: 'Initial Qualtity',
        minWidth: 50,
      },
      {
        id: 'qty',
        label: 'Quantity Remaining',
        minWidth: 50,
      },
      {
        id: 'unit',
        label: 'Unit',
        minWidth: 30,
      },
      {
        id: 'cost_of_goods',
        label: 'Cost of Goods',
        minWidth: 100,
      },
      {
        id: 'unit_cost',
        label: 'Unit Cost',
        minWidth: 80,
      },
      {
        id: 'selling_price',
        label: 'Unit Price',
        minWidth: 80,
      },
      {
        id: 'discount',
        label: 'Discount',
        minWidth: 70,
      },
      {
        id: 'wholesale_discount',
        label: 'Wholesale Discount',
        minWidth: 80,
      },
      {
        id: 'created_at',
        label: 'Created Date',
        minWidth: 100,
      },
      {
        id: 'expire_at',
        label: 'Expire Date',
        minWidth: 100,
        format: (value) =>
          new Date(value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
      },
    ];

    return (
      <div className={styles.mainDiv}>
        {isLoading === false ? (
          <Table columns={columns} rows={stocksData} />
        ) : (
          <div>
            <Stack spacing={1}>
              <Skeleton variant="rounded" height={20} />
              <Skeleton variant="rounded" height={20} />
              <Skeleton variant="rounded" height={20} />
              <Skeleton variant="rounded" height={20} />
              <Skeleton variant="rounded" height={20} />
            </Stack>
          </div>
        )}
      </div>
    );
  }
);

const mapStateToProps = (state) => ({
  auth: state.authState,
  stocksState: state.stocksState,
});

export default connect(mapStateToProps, {
  getStocks,
})(StockTable);
