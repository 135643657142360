import React, { memo, useEffect, useState } from 'react';
import styles from './index.module.css';
import Table from '../../../../../common/components/Table';
import { getSuppliers } from '../../actions/index';
import { connect } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const SupplierTable = memo(
  ({
    getSuppliers,
    auth: { branchList, branchId },
    suppliersState: { suppliersData, isLoading },
  }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterParams, setFilterParams] = useState({});

    useEffect(() => {
      console.log('branchId', branchId);
      if (branchId) {
        getSuppliers(filterParams);
      }
    }, [filterParams, branchId]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    useEffect(() => {
      console.log('suppliers data', suppliersData);
    }, [suppliersData]);

    const columns = [
      {
        id: 'companyname',
        label: 'Company Name',
        minWidth: 100,
      },
      {
        id: 'address',
        label: 'Address',
        minWidth: 100,
      },
      {
        id: 'contact_no',
        label: 'Contact No',
        minWidth: 100,
      },
    ];

    return (
      <div className={styles.mainDiv}>
        {isLoading === false ? (
          <Table columns={columns} rows={suppliersData} />
        ) : (
          <div>
            <Stack spacing={1}>
              <Skeleton variant="rounded" height={20} />
              <Skeleton variant="rounded" height={20} />
              <Skeleton variant="rounded" height={20} />
              <Skeleton variant="rounded" height={20} />
              <Skeleton variant="rounded" height={20} />
            </Stack>
          </div>
        )}
      </div>
    );
  }
);

const mapStateToProps = (state) => ({
  auth: state.authState,
  suppliersState: state.suppliersState,
});

export default connect(mapStateToProps, {
  getSuppliers,
})(SupplierTable);
