import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from '@redux-devtools/extension';
import { thunk } from 'redux-thunk';
import dashboard from './modules/dashboard/reducers/index';
import authState from './modules/auth/reducers/index';
import commonState from './common/reducers/index';
import paymentState from './modules/payment/reducers/index';
import statsState from './modules/inventory/stats/reducers/index';
import supplierInvoiceState from './modules/inventory/invoices/reducers/index';
import productsState from './modules/inventory/products/reducers/index';
import categoriesState from './modules/inventory/categories/reducers/index';
import suppliersState from './modules/inventory/suppliers/reducers/index';
import stocksState from './modules/inventory/stocks/reducers/index';

const intialState = {};
const middleWare = [thunk];
export const store = configureStore({
  reducer: {
    dashboard,
    authState,
    commonState,
    paymentState,
    statsState,
    supplierInvoiceState,
    productsState,
    categoriesState,
    suppliersState,
    stocksState,
  },
});
export default store;
