import { SET_PRODUCTS, SET_PRODUCTS_DATA_LOADING } from './types';
import store from '../../../../store';
import Axios from '../../../../common/util/Axios';

export const createProduct = (product) => async (dispatch) => {
  try {
    dispatch({ type: SET_PRODUCTS_DATA_LOADING });

    const companyId = store.getState().authState.companyId;
    const requestBody = {
      ...product,
      companyId,
    };

    const res = await Axios.post(`/api/dashboard/products`, requestBody);

    return true;
  } catch (err) {
    return false;
  }
};

export const getProducts = (params) => async (dispatch) => {
  try {
    dispatch({ type: SET_PRODUCTS_DATA_LOADING });

    const companyId = store.getState().authState.companyId;
    const branchId = store.getState().authState.branchId;
    const queryParams = {
      ...params,
      companyId,
      branchId,
    };

    console.log(queryParams, 'queryParams');

    const res = await Axios.get(`/api/dashboard/products/search-with-stock`, {
      params: queryParams,
    });

    dispatch({
      type: SET_PRODUCTS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: SET_PRODUCTS,
      payload: [],
    });
  }
};
