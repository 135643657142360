import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { connect } from "react-redux";
import { setBranchId } from "../../../auth/actions";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { InputLabel } from "@mui/material";
import PropTypes from "prop-types";
import CurrentStockDetailsSection from "./CurrentStockDetailsSection";
import { getStatsDetails } from "../actions";

const Stats = ({ setBranchId, auth: { branchList, branchId, companyId } }) => {
  const handleChangeBranch = (event) => {
    setBranchId(event.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Stats</h1>
        {branchId && (
          <FormControl className={"branch-select-dropdown"} size="small">
            <InputLabel id="test-select-label">Branch</InputLabel>
            <Select
              labelId="test-select-label"
              id="demo-simple-select-helper"
              value={branchId}
              label="Branch"
              onChange={handleChangeBranch}
            >
              {branchList.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <div className={styles.body}>
        <CurrentStockDetailsSection />
      </div>
    </div>
  );
};

Stats.propTypes = {
  setBranchId: PropTypes.func.isRequired,
  getStatsDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authState,
});

export default connect(mapStateToProps, {
  setBranchId,
  getStatsDetails,
})(Stats);
