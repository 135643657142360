import React, { useEffect } from 'react';

const AddNewSupplier = () => {
  useEffect(() => {
    console.log('OPENS THE PAGE');
  }, []);

  return <div>AddNewSupplier</div>;
};

export default AddNewSupplier;
