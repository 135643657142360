import { SET_SUPPLIERS, SET_SUPPLIERS_DATA_LOADING } from './types';
import store from '../../../../store';
import Axios from '../../../../common/util/Axios';

export const getSuppliers = (params) => async (dispatch) => {
  try {
    dispatch({ type: SET_SUPPLIERS_DATA_LOADING });
    const companyId = store.getState().authState.companyId;

    const queryParams = {
      ...params,
      companyId,
    };

    const res = await Axios.get(`/api/dashboard/suppliers/find-all`, {
      params: queryParams,
    });

    dispatch({
      type: SET_SUPPLIERS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: SET_SUPPLIERS,
      payload: [],
    });
  }
};
