import React, { memo, useEffect, useState } from "react";
import styles from "./index.module.css";
import Table from "../../../../../common/components/Table";
import { getProducts } from "../../actions";
import { connect } from "react-redux";

const ProductTable = memo(({
  getProducts, 
  productsState: { productsData },
  auth: { branchList, branchId }
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterParams, setFilterParams] = useState({});

  useEffect(() => {
    if (branchId) {
      getProducts(filterParams);
    }
  }, [filterParams, branchId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "code", 
      label: "Code", 
      minWidth: 50 
    },
    { id: "name", 
      label: "Name", 
      minWidth: 200 
    },
    {
      id: "barcode",
      label: "Barcode",
      minWidth: 100,
    },
    {
      id: "category_name",
      label: "Category",
      minWidth: 100,
    },
    {
      id: "stockamount",
      label: "Stock",
      minWidth: 100,
      format: (value) => value.toFixed(2),
    },
  ];

  return (
    <div className={styles.mainDiv}>
      <Table 
        columns={columns}
        rows={productsData}
      />
    </div>
  );
});

const mapStateToProps = state => ({
  productsState: state.productsState,
  auth: state.authState,
});

export default connect(mapStateToProps, {
  getProducts
})(ProductTable);